import { Pipe, PipeTransform } from '@angular/core';

@Pipe
(
	{
		name: 'truncate',
		standalone: true
	}
)

export class TruncateStringPipe implements PipeTransform
{
	public truncatedString: string;

	transform(input: string, maxLength: number, trailingCharacters: string): string
	{
		let tempString = input;

		if (tempString.length > maxLength)
		{
			tempString = input.slice(0, maxLength);

			let lastSpaceIndex = tempString.lastIndexOf(' ');

			tempString = input.slice(0, lastSpaceIndex);
			tempString += trailingCharacters;
		}

		this.truncatedString = tempString;

		return this.truncatedString;
	}
}
